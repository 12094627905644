/* bootstrap */
@import "../node_modules/bootstrap/dist/css/bootstrap-grid.css";
/*Fullcalender */
@import '../node_modules/fullcalendar/dist/fullcalendar.min.css';

body {
    margin: 0;
    padding: 0;
}

mat-card {
    text-align: center;
}

.fill-remaining-space {
    /* This fills the remaining space, by using flexbox.
       Every toolbar row uses a flexbox row layout. */
    flex: 1 1 auto;
}

button.small {
    font-size: 0.6em;
}

p > mat-icon {
    display: inline-flex;
    vertical-align: middle;
}

/*Background + center item vertical*/

mat-tab-header {
    margin-bottom: 20px;
}

body, html {
    height: 100%;
}

.container-fluid {
    padding: 30px 20px 30px 20px;
}

a:link, a:visited, a:hover, a:active {
    color: #FFF;
    text-decoration: none;
}

/* used for warehouse => locations breadcrumb */
h2 a:link, h2 a:visited, h2 a:hover, h2 a:active {
    color: #000;
    text-decoration: none;
}

.mat-tab-body-wrapper {
    padding-top: 20px;
}
mat-form-field {
    width: 100%;
}

app-authenticated, app-unauthenticated {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

app-authenticated > *:nth-child(3), app-unauthenticated > *, .mat-sidenav-container > * {
    flex-grow: 1;
    position: relative;
}

app-authenticated > *:first-child, app-authenticated > *:last-child, app-unauthenticated > *:first-child, app-unauthenticated > *:last-child {
    flex-grow: 0;
}

p {
    font: 400 16px/20px Roboto, "Helvetica Neue", sans-serif;
}

[hidden] {
    display: none !important;
}

/* Styling tables without using mat-table */
.mat-table {
    display: table;
    width: 100%;
}

.mat-row,
.mat-header-row {
    display: flex;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba(0, 0, 0, .12);
    align-items: center;
    min-height: 48px;
    padding: 0 24px;
}

.mat-cell,
.mat-header-cell {
    flex: 1;
    overflow: hidden;
    word-wrap: break-word;
    text-align: left;
}

.row {
    margin: 0;
}

.mat-tab-body-wrapper {
    height: 100%;
}

.pull-right {
    float: right;
    text-align: right;
}

.mat-dialog-container {
    display: flex !important;
    flex-direction: column;
}

.mat-tab-body-wrapper {
    padding-top: 0;
}


/* mat cards */
.mat-card-header-text {
    width: 100%;
}

.msgbox {
  cursor: pointer;
  border-radius: 10px;
  text-align: center;
}

/* Fullcalendar */
.fc-button {
    border: 0;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 2px;
    border-color: rgba(0, 0, 0, 0.87);
}

.fc-state-hover {
    transition: none;
    background-position: 0, 0;
}

app-fullcalendar {
    font: 400 16px/28px Roboto, "Helvetica Neue", sans-serif;
}

mat-dialog-actions button {
    float: right;
}

.mat-expansion-panel-header .mat-content {
    overflow: visible;
}

.bg-success {
    background: #28a745;
}

.bg-danger {
    background: #dc3545;
}

.bg-warning {
    background: #ffc107;
}

.bg-info {
    background: #17a2b8;
}

.bg-gray {
    background: #b2b2b2;
}

.text-center {
    text-align: center;
}

.text-success {
    color: #28a745;
}

.text-danger {
    color: #dc3545;
}

.text-warning {
    color: #ffc107;
}

.text-info {
    color: #17a2b8;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 1em !important;
}

.button-tab mat-tab-header {
    max-width: calc(100% - 100px) !important;
}
/* customizing theme */
@font-face {
    font-family: 'MrDodo';
    src: url(assets/fonts/mrdodo-regular.eot) format('embedded-opentype'), url(assets/fonts/mrdodo-regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Veneer';
    src: url(assets/fonts/veneer.eot) format('embedded-opentype'), url(assets/fonts/veneer.ttf) format('truetype');
}

/* Workaround to get bootstrap grid working inside the mat-expansion panels */
.mat-expansion-panel-body {
    box-sizing: border-box;
}
