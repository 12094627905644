/*@import "@angular/material/prebuilt-themes/indigo-pink.css";
/*@import "@angular/material/prebuilt-themes/pink-bluegrey.css";
/*@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
/*@import "@angular/material/prebuilt-themes/purple-green.css";

 */
@use '@angular/material' as mat;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// **Be sure that you only ever include this mixin once!**
@include mat.core();

// Custom font
$custom-typography: mat.define-typography-config(
  $font-family: 'MrDodo',
  $headline: mat.define-typography-level(32px, 48px, 700),
  $body-1: mat.define-typography-level(16px, 24px, 500)
);

// Custom E&C theme
$edgard-cooper-primary: mat.define-palette(mat.$green-palette);
$edgard-cooper-accent: mat.define-palette(mat.$green-palette, A200, A100, A400);
$edgard-cooper-warn: mat.define-palette(mat.$deep-orange-palette);
$edgard-cooper-theme: mat.define-light-theme($edgard-cooper-primary, $edgard-cooper-accent, $edgard-cooper-warn);

.edgard-cooper-theme {
  @include mat.all-component-themes($edgard-cooper-theme);

  // Override typography for all Angular Material, including mat-base-typography and all components.
  @include mat.all-component-typographies($custom-typography);

  // Override the typography in the core CSS.
  @include mat.core($custom-typography);
}

// Indigo Pink
$indigo-pink-primary: mat.define-palette(mat.$indigo-palette);
$indigo-pink-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$indigo-pink-theme: mat.define-light-theme($indigo-pink-primary, $indigo-pink-accent);

// Include the default theme styles.
@include mat.all-component-themes($indigo-pink-theme);

// Deep Purple amber
$deeppurple-amber-primary: mat.define-palette(mat.$deep-purple-palette);
$deeppurple-amber-accent: mat.define-palette(mat.$amber-palette, A200, A100, A400);
$deeppurple-amber-theme: mat.define-light-theme($deeppurple-amber-primary, $deeppurple-amber-accent);

.deeppurple-amber-theme {
  @include mat.all-component-themes($deeppurple-amber-theme);
}

// Pink Bluegrey
$pink-bluegrey-primary: mat.define-palette(mat.$pink-palette);
$pink-bluegrey-accent: mat.define-palette(mat.$blue-grey-palette, A200, A100, A400);
$pink-bluegrey-warn: mat.define-palette(mat.$deep-orange-palette);
$pink-bluegrey-theme: mat.define-dark-theme($pink-bluegrey-primary, $pink-bluegrey-accent, $pink-bluegrey-warn);

.pink-bluegrey-theme {
  @include mat.all-component-themes($pink-bluegrey-theme);
}

// Purple Green
$purple-green-primary: mat.define-palette(mat.$purple-palette);
$purple-green-accent: mat.define-palette(mat.$green-palette, A200, A100, A400);
$purple-green-warn: mat.define-palette(mat.$deep-orange-palette);
$purple-green-theme: mat.define-dark-theme($purple-green-primary, $purple-green-accent, $purple-green-warn);

.purple-green-theme {
  @include mat.all-component-themes($purple-green-theme);
}
